import Swal from "sweetalert2";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { axiosInstance } from "@/axios";

const firebaseConfig = {
  apiKey: "AIzaSyCME2CYaIso7PngM2wihV4LZGB_JzZoKjc",
  authDomain: "silema.firebaseapp.com",
  projectId: "silema",
  storageBucket: "silema.appspot.com",
  messagingSenderId: "565898129046",
  appId: "1:565898129046:web:7245c59623deb1f72c1cb7",
  measurementId: "G-1J6JS28GMD",
};

export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// Función que espera hasta que el usuario esté logueado y disponible
function checkUserLoginStatus() {
  return new Promise((resolve, reject) => {
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user); // Usuario autenticado, continúa con el proceso
      } else {
        reject(new Error("Usuario no autenticado."));
      }
    });
  });
}

export async function initializeFCM() {
  try {
    // Esperamos a que el usuario esté autenticado completamente
    const user = await checkUserLoginStatus();

    // Registramos el Service Worker
    const registration = await navigator.serviceWorker.register(
      "/custom-service-worker.js"
    );

    // Verificamos si el permiso de notificación ya fue otorgado
    if (Notification.permission === "granted") {
      // Si las notificaciones están permitidas, obtenemos el token FCM
      const fcmToken = await getToken(messaging, {
        vapidKey:
          "BDPwfWwBsKnpUjWi5KJLwRVa4u25xS7glifynH4oWBpcAvx_4B0W5y6DaDlA707suKDbemWU_GPUy8hAm6ea6Xc",
        serviceWorkerRegistration: registration,
      });

      // Guardamos el token junto con el UID del usuario autenticado
      await saveFCMToken(fcmToken, user.uid);

      // Manejar los mensajes recibidos en primer plano
      onMessage(messaging, (payload) => {
        console.log("Mensaje recibido en primer plano:", payload);
        if (payload && payload.notification) {
          Swal.fire({
            title: payload.notification.title,
            text: payload.notification.body,
            icon: "info",
          });
        } else {
          console.log("No se recibió la notificación correctamente.");
        }
      });
    } else if (Notification.permission === "denied") {
      Swal.fire({
        title: "Notificaciones bloqueadas",
        text: "Has bloqueado el permiso de notificaciones, ponte en contacto con informática.",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      });
    } else {
      // Si el permiso no ha sido solicitado, lo pedimos al usuario
      Swal.fire({
        title: "Activar Notificaciones",
        text: "¿Quieres activar las notificaciones para estar al tanto de las novedades?",
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#28A745",
        confirmButtonText: "Sí, activar",
        cancelButtonText: "No, gracias",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const permission = await Notification.requestPermission();
          if (permission === "granted") {
            // Obtenemos el token FCM una vez que se concede el permiso
            const fcmToken = await getToken(messaging, {
              vapidKey:
                "BDPwfWwBsKnpUjWi5KJLwRVa4u25xS7glifynH4oWBpcAvx_4B0W5y6DaDlA707suKDbemWU_GPUy8hAm6ea6Xc",
              serviceWorkerRegistration: registration,
            });

            await saveFCMToken(fcmToken, user.uid);
          } else {
            console.log("Permiso de notificación no concedido.");
          }
        }
      });
    }
  } catch (error) {
    console.error("Error en el proceso de inicialización de FCM:", error);
  }
}

// Función para guardar el token FCM en el backend
async function saveFCMToken(token, uid) {
  try {
    const auth = getAuth(app);
    const currentUser = auth.currentUser;

    if (currentUser) {
      const accessToken = await currentUser.getIdToken(); // Obtener el token de acceso

      await axiosInstance
        .post(
          `/notificaciones/saveTokenFCM?token=${token}&uid=${uid}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(
            `%c${response.data.data.data}`,
            "color: green; background-color: black; font-size: 16px;"
          );
        });
    } else {
      console.error("No hay un usuario autenticado.");
    }
  } catch (error) {
    console.error(
      "Error al guardar el token FCM:",
      error.response ? error.response.data : error
    );
  }
}

// Iniciar el proceso cuando la app esté completamente lista
document.addEventListener("DOMContentLoaded", () => {
  initializeFCM();
});
