export default {
  namespaced: true,
  state: {
    notificacionesPendientes: [],
  },
  mutations: {
    setNotificacionesPendientesMutation(state, notificaciones) {
      state.notificacionesPendientes = notificaciones;
    },
  },
  getters: {
    notificacionesPendientes: (state) => state.notificacionesPendientes,
  },
  actions: {
    setNotificacionesPendientes({ commit }, notificaciones) {
      commit("setNotificacionesPendientesMutation", notificaciones);
    },
  },
};
