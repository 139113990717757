import "mdb-vue-ui-kit/css/mdb.min.css";
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "mdb-vue-file-upload/css/mdb-vue-file-upload.min.css";
import "mdb-vue-calendar/css/mdb-vue-calendar.min.css";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import "mdb-vue-wysiwyg-editor/css/mdb-vue-wysiwyg-editor.min.css";
import { Settings } from "luxon";
import packageInfo from "../package.json";

Settings.defaultZone = "Europe/Madrid";
const app = createApp(App);
app.config.globalProperties.$appVersion = packageInfo.version;

app
  .use(store)
  .use(router)
  .component("EasyDataTable", Vue3EasyDataTable)
  .mount("#app");
